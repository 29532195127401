import React, { useState } from 'react'
import isMobile from '../helpers/isMobile'
import { removeTags } from '../hooks/common'
import DangerousHTML from './DangerousHTML'
import SeeMore from './SeeMore'

export default function FAQListingNew({ faqList = [], className = "" }) {
  const [showAll, setShowAll] = useState(false)
  if (!faqList.length) return null
  return (

    <div className={`faq-container ${className ? className : ""}`}>

      <h2 className="bold text-center">FAQs</h2>

      <div className="faq-wrapper container">
        {
          faqList.slice(0, (showAll ? faqList.length : (isMobile() ? 1 : 4))).map(faq => <div className="faq-qna">
            <div className="faq-quest">
              <p>{faq.question}</p>
            </div>
            <div className="faq-ans">
              {/* {
                <SeeMore text={removeTags(faq.answer)} />
              } */}
              <DangerousHTML html={faq.answer} />
            </div>
          </div>)
        }
      </div>
      {
        faqList.length > (isMobile() ? 1 : 4) ?
          <div className="more-btn container text-center"> <button
            className=" "
            onClick={() => setShowAll(!showAll)}
            style={{ cursor: 'pointer', lineHeight: '24px', display: 'inline-block' }}
          >
            {!showAll ? 'MORE ' : 'LESS'}
          </button></div> : null
      }
    </div>
  )
}
