import React, { useState } from 'react';

const Zipcodes = React.memo(({ contentLink: contentData, subHeading, heading, section }) => {
  const [showText, setShowText] = useState(false);

  const seeMore = () => {
    setShowText(!showText);
  };

  if (contentData?.length) {
    return (
      <div className={`bottom-catalog-wrapper ${section}-listing`}>
        <div className="container">
          <h2 className="bottom-catalog-title bold text-center mb-2">{heading}</h2>
          {subHeading ? <p>{subHeading}</p> : null}
          <div className={`bottom-catalog-listing two-rem-mt ${showText ? '' : 'seeMore'}`}>
            {contentData?.map(zipcode => {
              return <a className="bottom-catalog-row">{zipcode}</a>;
            })}
          </div>
          <div className="more-btn text-center">
            <button className=" " onClick={seeMore} style={{ cursor: 'pointer', lineHeight: '24px', display: 'inline-block' }}>
              {!showText ? 'MORE' : 'LESS'}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null;
});

export default Zipcodes;
